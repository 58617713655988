import React from 'react';

import service1 from '../assets/images/Software_Development.png';
import service2 from '../assets/images/Wev_Development.png';
import service3 from '../assets/images/Analytic_Solutions.png';
import service4 from '../assets/images/Clould_DevOps.png';
import service5 from '../assets/images/Product_Design.png';
import service6 from '../assets/images/Data_Center.png';

const HomeServices = () => {
  return (
    <div id="rs-service" class="rs-services main-home gray-bg pt-110 pb-120 md-pt-70 md-pb-70">
        <div class="container">
            <div class="sec-title2 text-center mb-46">
                <div class="sub-text">Services</div>
                <div class="title">We Are Offering All Kinds of IT Solutions Services</div>
                <div class="heading-line"></div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 mb-30">
                    <div class="services-item">
                        <div class="services-icon">
                            <div class="image-part"><img src={service1} alt="Software Development"/></div>
                        </div>
                        <div></div>
                        <div class="services-content">
                            <div class="services-text">
                                <h3 class="services-title"><a href="/service/service-details">Software Development</a></h3></div>
                            <div class="services-desc">
                                <p>We specialize in delivering end-to-end software development services tailored to meet your unique needs. From initial concept and design to deployment and support.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-30">
                    <div class="services-item">
                        <div class="services-icon">
                            <div class="image-part"><img src={service2} alt="Web Development" /></div>
                        </div>
                        <div></div>
                        <div class="services-content">
                            <div class="services-text">
                                <h3 class="services-title"><a href="/service/service-details">Web Development</a></h3></div>
                            <div class="services-desc">
                                <p>The web development experts of Lazlosoftware can develop a user-friendly website with web pages having easy-to-navigate features.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-30">
                    <div class="services-item">
                        <div class="services-icon">
                            <div class="image-part"><img src={service3} alt="Analytic Solutions" /></div>
                        </div>
                        <div></div>
                        <div class="services-content">
                            <div class="services-text">
                                <h3 class="services-title"><a href="/service/service-details">Analytic Solutions</a></h3></div>
                            <div class="services-desc">
                                <p>We specialize in empowering businesses to harness the power of data to drive intelligent decision-making and achieve strategic goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 md-mb-30">
                    <div class="services-item">
                        <div class="services-icon">
                            <div class="image-part"><img src={service4} alt="Clould &amp; DevOps" /></div>
                        </div>
                        <div></div>
                        <div class="services-content">
                            <div class="services-text">
                                <h3 class="services-title"><a href="/service/service-details">Clould &amp; DevOps</a></h3></div>
                            <div class="services-desc">
                                <p>Cloud services provide on-demand access to computing resources like servers, storage, databases, and software, over the internet.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 sm-mb-30">
                    <div class="services-item">
                        <div class="services-icon">
                            <div class="image-part"><img src={service5} alt="Product &amp; Design" /></div>
                        </div>
                        <div></div>
                        <div class="services-content">
                            <div class="services-text">
                                <h3 class="services-title"><a href="/service/service-details">Product &amp; Design</a></h3></div>
                            <div class="services-desc">
                                <p>We specialize in turning ideas into impactful solutions by combining innovative design and strategic product development.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="services-item">
                        <div class="services-icon">
                            <div class="image-part"><img src={service6} alt="Data Center" /></div>
                        </div>
                        <div></div>
                        <div class="services-content">
                            <div class="services-text">
                                <h3 class="services-title"><a href="/service/service-details">Data Center</a></h3></div>
                            <div class="services-desc">
                                <p>We specialize in delivering world-class data center services designed to meet the unique needs of modern enterprises.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeServices