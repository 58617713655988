import React from 'react';
import '../styles/Header.css'
import { Link } from "react-router-dom";
import logo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/fontawesome-free-regular';
import { faPhone } from '@fortawesome/fontawesome-free-solid';
import { faFacebook, faInstagram, faPinterest, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
    return (
        <div className="full-width-header">
            <header id="rs-header" className="rs-header">
                <div className="topbar-area hidden-md">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2">
                                <div className="logo-part">
                                    <Link to="/" className='logo'><img className="ml-2" src={logo} alt="Logo" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-10 text-right">
                                <ul className="rs-contact-info">
                                    <li className="contact-part"><FontAwesomeIcon icon={faLocationDot} /><span className="contact-info"><span>Address</span>NOIDA, Uttar Pradesh</span>
                                    </li>
                                    <li className="contact-part"><FontAwesomeIcon icon={faEnvelope} /><span className="contact-info"><span>E-mail</span><a href="mailto:support@lazlosoftwaresolution.com"> support@lazlosoftwaresolution.com</a></span>
                                    </li>
                                    <li className="contact-part"><FontAwesomeIcon icon={faPhone} /><span className="contact-info"><span>Phone</span>+91 9876543210</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menu-area menu-sticky">
                    <div className="container">
                        <div className="rs-menu-area">
                            <div className='main-menu'>
                                <nav className="rs-menu hidden-md">
                                    <ul className="nav-menu">
                                        <li className="current-menu-item">
                                            <a as="/" href="/">Home</a>
                                        </li>
                                        <li className=""><a className="" href="/about">About</a></li>
                                        <li className=""><a className="" href="/services">Services</a></li>
                                        <li className=""><a className="" href="/contact">Contact</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="expand-btn-inner search-icon hidden-sticky hidden-md">
                            <ul>
                                <li className="sidebarmenu-search">
                                    <a className="rs-search" href="#"><FontAwesomeIcon icon={faMagnifyingGlass} /></a>
                                </li>
                            </ul>
                            <div className="toolbar-sl-share">
                                <ul className="social">
                                    <li><a href="#"><FontAwesomeIcon icon={faFacebook} /></a></li>
                                    <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                    <li><a href="#"><FontAwesomeIcon icon={faPinterest} /></a></li>
                                    <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header