import React from 'react';

const HomeProcess = () => {
  return (
    <div class="rs-process pt-180 pb-120 md-pt-70 md-pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 md-mb-40">
                <div class="process-wrap">
                    <div class="sec-title mb-30">
                        <div class="sub-text new">Working Process</div>
                        <div class="title white-color">Our Working Process - How We Work For Our Customers</div>
                    </div>
                    <div class="btn-part mt-40"><a class="readon learn-more contact-us" href="/contact">Contact Us</a></div>
                </div>
            </div>
            <div class="col-lg-8 pl-30 md-pl-15">
                <div class="row">
                    <div class="col-md-6 mb-40">
                        <div class="rs-addon-number">
                            <div class="number-text">
                                <div class="number-area">1.</div>
                                <div class="number-title">
                                    <h3 class="title">Discovery</h3></div>
                                <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-40">
                        <div class="rs-addon-number">
                            <div class="number-text">
                                <div class="number-area">2.</div>
                                <div class="number-title">
                                    <h3 class="title">Planning</h3></div>
                                <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 sm-mb-40">
                        <div class="rs-addon-number">
                            <div class="number-text">
                                <div class="number-area">3.</div>
                                <div class="number-title">
                                    <h3 class="title">Execute</h3></div>
                                <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="rs-addon-number">
                            <div class="number-text">
                                <div class="number-area">4.</div>
                                <div class="number-title">
                                    <h3 class="title">Deliver</h3></div>
                                <p class="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh luctus, in porttitor theo lacus egestas.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default HomeProcess