import React from 'react';
import '../styles/Page.css';
import HomeMainContent from './HomeMainContent';


const Home = () => {
  return (
    <HomeMainContent />
    
  )
}

export default Home