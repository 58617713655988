import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/fontawesome-free-solid';
import { faEnvelope } from '@fortawesome/fontawesome-free-regular';
import { faFacebook, faInstagram, faPinterest, faTwitter } from "@fortawesome/free-brands-svg-icons";

import footerlogo from '../assets/logo.png';

const Footer = () => {
  return (
    <footer class="rs-footer">
        <div class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4">
                        <div class="footer-logo">
                            <a href="/"><img src={footerlogo} alt="Logo" /></a>
                        </div>
                        <div class="textwidget pb-30">
                            <p>At Lazlo Software Solutions, we specialize in delivering cutting-edge software services and technology-driven solutions tailored to empower businesses in the digital age.</p>
                        </div>
                        <ul class="footer-social md-mb-30">
                            <li><a href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                            <li><a href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                            <li><a href="/"><FontAwesomeIcon icon={faPinterest} /></a></li>
                            <li><a href="/"><FontAwesomeIcon icon={faInstagram} /></a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                        <h3 class="widget-title">IT Services</h3>
                        <ul class="site-map">
                            <li><a href="/service/software-development">Software Development</a></li>
                            <li><a href="/service/Web-development">Web Development</a></li>
                            <li><a href="/service/analytic-solutions">Analytic Solutions</a></li>
                            <li><a href="/service/cloud-and-devOps">Cloud and DevOps</a></li>
                            <li><a href="/service/product-design">Product Design</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12 md-mb-30">
                        <h3 class="widget-title">Contact Info</h3>
                        <ul class="address-widget">
                            <li><FontAwesomeIcon icon={faLocationDot} />
                                <div class="desc">NOIDA, Uttar Pradesh</div>
                            </li>
                            <li><FontAwesomeIcon icon={faPhone} />
                                <div class="desc"><a href="/">(+91)9876543210</a></div>
                            </li>
                            <li><FontAwesomeIcon icon={faEnvelope} />
                                <div class="desc"><a href="/">support@lazlosoftwaresolution.com</a></div>
                            </li>
                            <li><FontAwesomeIcon icon={faClock} />
                                <div class="desc">Opening Hours: 10:00 - 18:00</div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-12 col-sm-12">
                        <h3 class="widget-title">Newsletter</h3>
                        <p class="widget-desc">Stay updated with our latest insights                        .</p>
                        <p>
                            <div class="newsletter-area pt-75 pb-40">
                                <div class="single-newsletters">
                                    <div class="newsletter-form">
                                        <div>
                                            <input type="email" placeholder="Your email" />
                                            <button>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row y-middle">
                    <div class="col-lg-6 text-right md-text-center md-mb-10 order-last">
                        <ul class="copy-right-menu">
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About</a></li>
                            <li><a href="/blog">Blog</a></li>
                            <li><a href="/shop">Shop</a></li>
                            <li><a href="/faq">FAQs</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <div class="copyright md-text-center md-mb-10">
                            <p>© 2024 All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer