import React from 'react';

import about1 from '../assets/images/about-part-1.8548988a.png';
import about12 from '../assets/images/about-part-1-2.0d9e790e.png';
import about13 from '../assets/images/about-part-1-3.ad6eb0b3.png';

const HomeAbout = () => {
  return (
    <div id="rs-about" class="rs-about pt-130 pb-190 md-pt-80 md-pb-80 sm-pt-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="img-wrap">
                            <img class="main" src={about1} alt=""/>
                            <div class="ly1"><img class="dance" src={about12} alt=""/></div>
                            <div class="ly2"><img class="dance2" src={about13} alt=""/></div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 pl-40 md-pl-14 md-pt-240 sm-pt-66">
                        <div class="sec-title mb-30">
                            <div class="sub-text">About</div>
                            <div class="title pb-20">We Are Increasing Business Success With Technology</div>
                            <div class="desc pr-90 md-pr-0">Over 25 years working in IT services developing software applications and mobile apps for clients all over the world.</div>
                        </div>
                        <div class="row">
                            <div class="col-lg-10">
                                <div class="rs-skillbar style1">
                                    <div class="main-div">
                                        <p class="progress-label" style={{marginBottom: '10px', fontWeight: '500', fontSize: '14px'}}>Software Development</p>
                                        <div class="progress-box" style={{height: '7px', width: '100%',  backgroundColor: 'rgb(238, 238, 238)', borderRadius: '0px'}}>
                                            <div class="progress-figure" style={{height: '100%', width: '92%', backgroundColor: 'rgb(255, 0, 0)', transition: 'width 1s ease-in-out', borderRadius: 'inherit', textAlign: 'right'}}><span class="Progress-count" >92%</span></div>
                                        </div>
                                    </div>
                                    <div class="main-div">
                                        <p class="progress-label" style={{marginBottom: '10px', fontWeight: '500', fontSize: '14px'}}>Cyber Security</p>
                                        <div class="progress-box" style={{height: '7px', width: '100%',  backgroundColor: 'rgb(238, 238, 238)', borderRadius: '0px'}}>
                                            <div class="progress-figure" style={{height: '100%', width: '80%', backgroundColor: 'rgb(255, 0, 0)', transition: 'width 1s ease-in-out', borderRadius: 'inherit', textAlign: 'right'}}><span class="Progress-count">80%</span></div>
                                        </div>
                                    </div>
                                    <div class="main-div">
                                        <p class="progress-label" style={{marginBottom: '10px', fontWeight: '500', fontSize: '14px'}}>Artificial Intelligence</p>
                                        <div class="progress-box" style={{height: '7px', width: '100%',  backgroundColor: 'rgb(238, 238, 238)', borderRadius: '0px'}}>
                                            <div class="progress-figure" style={{height: '100%', width: '95%', backgroundColor: 'rgb(255, 0, 0)', transition: 'width 1s ease-in-out', borderRadius: 'inherit', textAlign: 'right'}}><span class="Progress-count" >95%</span></div>
                                        </div>
                                    </div>
                                    <div class="main-div">
                                        <p class="progress-label" style={{marginBottom: '10px', fontWeight: '500', fontSize: '14px'}}>Web Development</p>
                                        <div class="progress-box" style={{height: '7px', width: '100%',  backgroundColor: 'rgb(238, 238, 238)', borderRadius: '0px'}}>
                                            <div class="progress-figure" style={{height: '100%', width: '78%', backgroundColor: 'rgb(255, 0, 0)', transition: 'width 1s ease-in-out', borderRadius: 'inherit', textAlign: 'right'}}><span class="Progress-count" >78%</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-part mt-54"><a class="readon learn-more" href="/about">Learn More</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default HomeAbout