import React from 'react';
import HomeAbout from './HomeAbout';
import HomeServices from './HomeServices';
import HomeCallUs from './HomeCallUs';
import HomeProcess from './HomeProcess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
const HomeMainContent = () => {
  return (
    <div>
        <div className="rs-banner style1">
            <div className="banner-height pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-12 pt-40 pr-140 md-pt-0 md-mb-60 md-pr-14">
                            <div className="banner-content">
                                <h2 className="bnr-title" data-animation="fadeInLeft" data-delay=".3s">IT Consulting Services For Your Business</h2>
                                <p className="bnr-desc" data-animation="fadeInLeft" data-delay=".5s">We are leading technology solutions providing company all over the world doing over 40 years.</p>
                                <div className="banner-video"><a className="popup-video"><FontAwesomeIcon icon={faPlay} /></a></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 pl-70 lg-pl-30 md-pl-14">
                            <main>
                                <div className="appointment-style white-bg">
                                    <div className="form-title">
                                        <h3 className="title">Schedule Your Appointment</h3>
                                        <p className="desc">We here to help you 24/7 with experts</p>
                                    </div>
                                    
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HomeAbout />
        <HomeServices />
        <HomeCallUs />
        <HomeProcess />
    </div>
  )
}

export default HomeMainContent