import React from 'react';
import contacthere from '../assets/images/contact-here.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/fontawesome-free-solid';

const HomeCallUs = () => {
  return (
    <div class="rs-call-us relative pt-120 md-pt-70 md-pb-80">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 hidden-md">
                    <div class="image-part"><img src={contacthere} alt="" /></div>
                </div>
                <div class="col-lg-6 pb-120 md-pb-80">
                    <div class="rs-contact-box text-center">
                        <div class="address-item mb-26">
                            <div class="address-icon"><FontAwesomeIcon icon={faPhone} /></div>
                        </div>
                        <div class="sec-title3 text-center">
                            <div class="sub-text">CALL US 24/7</div>
                            <div class="title">(+123) 456-9989</div>
                            <div class="desc">Have any idea or project for in your mind call us or schedule a appointment. Our representative will reply you shortly.</div>
                        </div>
                        <div class="btn-part mt-40"><a class="readon lets-talk" href="/contact">Let's Talk</a></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rs-counter style1">
            <div class="row">
                <div class="col-md-4 sm-mb-40">
                    <div class="counter-wrapper single-counter text-center">
                        <div class="counter-text">
                            <h3 class="counter"><span>80</span><span class="counter-prefix">k</span></h3><span class="counter-title">Happy Clients</span></div>
                    </div>
                </div>
                <div class="col-md-4 sm-mb-40">
                    <div class="counter-wrapper single-counter text-center">
                        <div class="counter-text">
                            <h3 class="counter"><span>50</span><span class="counter-prefix">+</span></h3><span class="counter-title">Companies</span></div>
                    </div>
                </div>
                <div class="col-md-4 sm-mb-40">
                    <div class="counter-wrapper single-counter text-center">
                        <div class="counter-text">
                            <h3 class="counter"><span>230</span><span class="counter-prefix">+</span></h3><span class="counter-title">Projects Done</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeCallUs