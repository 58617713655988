import React, { Component } from 'react'
import axios from 'axios'

class PostList extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            posts:[]
        }
    }

    componentDidMount(){
        axios.get("https://dev1.bbb.yellowsapphiretech.com/rest/V1/products/1069630597")
        .then(response => {
            this.setState({posts:response.data});
            console.log(response);
        })
        .catch(error => {
            console.log(error);
        })
    }

    render() {
        const {posts}= this.state
        return (
            <div>
                <h1>Post List</h1>
                {
                    posts.length ?
                    posts.map(post=><div key={post.id}>{post.name}</div>):null
                }
            </div>
        )
    }
}

export default PostList